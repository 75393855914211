var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Klien',
        href: {
          name: 'admin.clients'
        }
      }, {
        label: 'Detail Klien',
        href: {
          name: 'admin.clients-detail',
          query: _vm.$route.query
        }
      }, {
        label: 'Formulir Gizi',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '30px'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('QuizionaryContent', {
    attrs: {
      "role": _vm.authRole
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }