<template>
  <c-box w="full">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        { label: 'Manajemen Klien', href: { name: 'admin.clients' } },
        { label: 'Detail Klien', href: { name: 'admin.clients-detail', query: $route.query } },
        { label: 'Formulir Gizi', isCurrent: true },
      ]"
    />
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '30px']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <QuizionaryContent
        :role="authRole"
      />
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import QuizionaryContent from '@/components/quizionary/content'

export default {
  name: 'AdminClientsQuizionaryIndex',
  components: {
    BreadcrumbPath,
    QuizionaryContent,
  },
}
</script>
